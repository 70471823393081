import { searchBarHome, toggleButton } from './components/header'
import { footerSocialMedia } from './components/footer'
import { fixedBreadcrumb } from './components/breadcrumb'
import { bfcacheGTM, ctaGTM, dirtyFormGTM, whatsAppEventGTM } from './modules/marketing/gtm'
import { setUserId } from './modules/form-helpers'
import { metaCta } from './modules/marketing/meta-api'
import { maskCUIT } from '../../../benchmark/assets/js/admin/helpers/utils'

toggleButton() // header: burger button
searchBarHome() // header: search bar
footerSocialMedia() // footer: manejo de iconos redes sociales
fixedBreadcrumb() // breadcrumb fixed

// Marketing
ctaGTM()
dirtyFormGTM()
metaCta()
bfcacheGTM()
whatsAppEventGTM()

// Global forms
setUserId()

// Mask CUIT
const $cuitInputs = document.querySelectorAll("input[name='cuit']")
if ($cuitInputs) {
    $cuitInputs.forEach((input) => {
        input.addEventListener('input', () => maskCUIT(input))
    });
}

// Recaptcha ready

