export const STEPS = {
    PERIOD: 'PERIOD',
    COMPANIES: 'COMPANIES',
    QUESTIONS: 'QUESTIONS',
    TABLE: 'TABLE',
}

export const parseDate = (date, format) => {
    const d = new Date(date)
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
    const dateFormat = new Intl.DateTimeFormat('es-AR', options)

    const [{ value: day }, , { value: month }, , { value: year }] = dateFormat.formatToParts(d)

    switch (format) {
        case 'dmy':
            return `${day}-${month}-${year}`
        case 'ymd':
            return `${year}-${month}-${day}`
        case 'verbose':
            return new Intl.DateTimeFormat('es-AR', { year: 'numeric', month: 'short' }).format(d)
        default:
            return `${day}-${month}-${year}`
    }
}

export function maskCUIT(input) {
    let value = input.value.replace(/[^0-9]/g, '')

    if (value.length > 9) {
        value = value.slice(0, 2) + '-' + value.slice(2, -1) + '-' + value.slice(-1)
    } else if (value.length > 2) {
        value = value.slice(0, 2) + '-' + value.slice(2)
    }

    input.value = value
}
